import client from "@/api";
import store from "@/store";
import { _rotaMarcas, _rotaGetCSVMarcas } from "@/api/_caminhosApi";

export function getCSV(from, to) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .get(_rotaGetCSVMarcas(), {
      params: {
        from,
        to,
      },
      responseType: "blob",
    })
    .then(resp => {
      if (resp.status === 200) {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "marcas.csv");
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function listarMarcas(from, to, qtd, page) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("marcas/RESET_MARCAS", null);
  client
    .get(`${_rotaMarcas()}`, {
      params: {
        from,
        to,
        per_page: qtd,
        page: page,
      },
    })
    .then(resp => {
      if (resp.status === 200) {
        store.commit("marcas/SET_MARCAS", resp.data);
      }
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
